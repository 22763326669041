// @flow

export const SOUNDS = [
  'kick',
  'snare',
  'rim',
  'hat',
  'hat2',
  'clap',
  'crash',
  'ride',
];

export default null;
