// @flow

import styled from 'styled-components';

export default styled.section`
  padding: .75rem;
`;

export const Title = styled.div`
  font-size: .875rem;
  text-align: center;
  text-transform: uppercase;
`;

export const Content = styled.div`
  display: flex;
  padding-top: 1rem;
`;

